import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import jwtDecode from 'jwt-decode';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CONSTANT } from 'src/app/shared/util/constant/constant';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
/**
 * Servicio de autenticación de usuarios con JWT y IndexedDB
 */
export class AuthService {
  private url = `${environment.apiEndpoint}${CONSTANT.URL_LOGIN}`;

  constructor(
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) {}
  /**
   * Realiza una petición HTTP POST al servidor para autenticar al usuario
   * @param email Correo electrónico del usuario
   * @param password Contraseña del usuario
   */
  login(username: string, password: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    console.log('login');
    return this.http
      .post(this.url, { username, password }, { responseType: 'json' })
      .pipe(
        tap((response: any) => {
          if (response.accessToken) {
            this.setToken(response);
          } else {
            throw new Error('Token no encontrado en la respuesta');
          }
        }),
        catchError((error) => {
          if (error.status === 401) {
            this.spinner.hide();
            Swal.fire({
              title: 'Error',
              text: 'Usuario o contraseña incorrectos',
              icon: 'error',
              confirmButtonText: 'Aceptar',
            });
            return of(throwError(() => new Error(error)));
          }
          this.spinner.hide();
          Swal.fire({
            title: 'Error',
            text: 'Algo ocurrió al intentar ingresar',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
          return of(throwError(() => new Error(error)));
        }),
      );
  }

  /**
   * Setea el token en el IndexedDB y redirige al Home de la aplicación
   * @param token Token a guardar
   */
  private setToken(token: any) {
    sessionStorage.setItem(CONSTANT.TOKEN_NAME, token.accessToken);
    sessionStorage.setItem(CONSTANT.USER_NAME, token.name);
    sessionStorage.setItem(CONSTANT.USER_ROLE, token.role);
    this.router.navigate(['']);
  }

  /**
   * Promesa que obtiene el token del IndexedDB y retorna el token en string
   */
  getToken() {
    const tokenData = sessionStorage.getItem(CONSTANT.TOKEN_NAME);
    return tokenData ? tokenData : null;
  }

  /**
   * Promesa que obtiene el token del IndexedDB y retorna el token decodificado
   */
  getDecodedToken() {
    const tokenData = sessionStorage.getItem(CONSTANT.TOKEN_NAME);
    const decodedToken = jwtDecode(tokenData);
    return tokenData ? decodedToken : null;
  }

  /**
   * Promesa que limpia el token del IndexedDB y redirige al Login
   */
  logout() {
    this.spinner.show();
    sessionStorage.clear();
    this.spinner.hide();
    this.router.navigate(['/ingresar']);
  }

  /**
   * Promesa que verifica si el token está vigente y retorna un booleano
   */
  isAuthenticated(): boolean {
    const tokenData = sessionStorage.getItem(CONSTANT.TOKEN_NAME);
    if (!tokenData) {
      return false;
    }
    const currentTime = Math.floor(Date.now() / 1000);
    const decodedToken = jwtDecode(tokenData);
    if (decodedToken['exp'] < currentTime) {
      this.logout();
      return false;
    }
    return true;
  }

  /**
   * Promesa que obtiene la información del usuario desde el token decodificado
   * y retorna un objeto con la información
   */
  async getUserInfo(): Promise<any> {
    const decodedToken: any = await this.getDecodedToken();
    if (decodedToken) {
      const { username = decodedToken.username } = decodedToken.role;
      return username;
    }
    return null;
  }

  async getUserId(): Promise<any> {
    const decodedToken: any = await this.getDecodedToken();
    if (decodedToken) {
      const { id = decodedToken.id } = decodedToken;
      return id;
    }
    return null;
  }
}
