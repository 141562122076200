<div class="container">
  <div class="row center">
    <div class="col-12 text-center">
      <img class="img" src="assets/img/notFound/404.png" />
      <div class="position">
        <span class="title">¡No eres tu, soy yo!</span>
        <div class="subtitle pb-3">
          <span>Oooh! lo sentimos, el contenido que buscabas ya no esta disponible ... :(</span><br />
          <span> No perdamos el contacto…</span>
        </div>
        <div>
          <button class="btn btn-outline-danger" (click)="login()" value="Volver al inicio">Vuelve al Inicio</button>
        </div>
      </div>
    </div>
  </div>
</div>
