import { environment } from 'src/environments/environment';

export const CONSTANT = {
  TOKEN_NAME: 'token-entorno',
  USER_NAME: 'user-entorno',
  USER_ROLE: 'role-entorno',
  URL_MAIN: 'maintainer',
  URL_USERS: 'maintainer/users',
  URL_LOGIN: '/auth/login',
};

export const ApiRequest = {
  //users
  getUsers: environment.apiEndpoint + '/user',

  //upload file
  uploadFile: environment.apiEndpoint + '/attendance/upload',

  //attendance
  getAttendance: environment.apiEndpoint + '/attendance',

  // companies
  getCompanies: environment.apiEndpoint + '/attendance/companies',
};
