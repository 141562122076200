import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-maintainer',
  templateUrl: './footer-maintainer.component.html',
  styleUrls: ['./footer-maintainer.component.scss']
})
export class FooterMaintainerComponent {

}
