<aside class="sidebar shadow-sm">
  <div
    class="sidebar-profile d-flex flex-column justify-content-center pt-5 pb-3"
  >
    <div class="picture-wrapper mb-4">
      <div class="user-picture m-auto">
        <img src="./assets/img/user.jpg" class="img-fluid" alt="" />
      </div>
    </div>
    <div class="profile-details text-center pb-4">
      <p class="mb-0 text-uppercase name">{{ user }}</p>
      <small class="text-uppercase role">{{ getUserRole() }}</small>
    </div>
  </div>

  <nav class="sidebar-menu pt-3 pb-3">
    <div class="menu-header mt-3 mb-2">Menú</div>
    <ul class="menu-items">
      <li>
        <a
          href="#"
          routerLink="/{{ urlPrimary }}/home"
          routerLinkActive="active"
        >
          <i class="fa fa-tv"></i>
          <span>Dashboard</span>
        </a>
      </li>
      <li *ngIf="userRole === 'admin'">
        <a
          href="#"
          routerLink="/{{ urlPrimary }}/users"
          routerLinkActive="active"
        >
          <i class="fa fa-users"></i>
          <span>Gestion de usuarios</span>
        </a>
      </li>
    </ul>
  </nav>
</aside>
