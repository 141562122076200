import { Component, OnInit } from '@angular/core';
import { CONSTANT } from 'src/app/shared/util/constant/constant';

@Component({
  selector: 'app-sidebar-maintainer',
  templateUrl: './sidebar-maintainer.component.html',
  styleUrls: ['./sidebar-maintainer.component.scss'],
})
export class SidebarMaintainerComponent implements OnInit {
  urlPrimary = '';
  userRole = sessionStorage.getItem(CONSTANT.USER_ROLE);
  user = sessionStorage.getItem(CONSTANT.USER_NAME) || 'Usuario';
  public isCollapsed = true;

  ngOnInit() {
    this.urlPrimary = CONSTANT.URL_MAIN;
  }

  getUserRole() {
    if (this.userRole === 'admin') {
      return 'Administrador';
    } else {
      return 'Usuario';
    }
  }
}
