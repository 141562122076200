import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { AppService } from 'src/app/shared/services/app.service';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-navbar-maintainer',
  templateUrl: './navbar-maintainer.component.html',
  styleUrls: ['./navbar-maintainer.component.scss'],
})
export class NavbarMaintainerComponent implements OnInit {
  isCollapsed = true;

  constructor(
    private appService: AppService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {}

  toggleSidebarPin() {
    this.isCollapsed = !this.isCollapsed;
    this.appService.toggleSidebarPin();
  }
  toggleSidebar() {
    this.appService.toggleSidebar();
  }
  showChangePasswordModal() {
    const modalRef = this.modalService.open(ChangePasswordModalComponent, {
      size: 'md',
      animation: true,
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });

    modalRef.result.then(
      (result) => {
        if (result) {
          window.location.reload();
        }
      },
      () => {
        window.location.reload();
      },
    );
  }
  signOff() {
    Swal.fire({
      title: 'Alerta',
      text: '¿Quieres cerrar sesion?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      heightAuto: false,
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        window.location.reload();
      }
    });
  }
}
