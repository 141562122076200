<nav class="navbar navbar-expand-md fixed-top navbar-dark flex-fill">
  <!-- <button class="sidebar-toggler ml-4" type="button">
    <i class="fa fa-navicon"></i>
  </button> -->
  <!-- header -->
  <div class="navbar-header pl-2 pr-2 ml-3 text-center">
    <a class="navbar-brand m-0 w-100" href="#">
      <ng-container *ngIf="!isCollapsed">
        <img
          src="./assets/img/logo-color.png"
          alt="lightning logo"
          width="60px"
        />
      </ng-container>
      <ng-container *ngIf="isCollapsed">
        <img
          src="./assets/img/logo-color.png"
          alt="lightning logo"
          width="120px"
        />
      </ng-container>
    </a>
  </div>
  <!-- sidebar toggler -->
  <div class="sidebar-toggler ms-auto me-3">
    <a class="btn nav-link" (click)="toggleSidebar()"
      ><i class="fa fa-bars"></i
    ></a>
  </div>
  <!-- right menu toggler -->
  <div class="nav-toggler-right me-4 d-md-none">
    <button
      class=""
      type="button"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseBasic"
    >
      <!-- <span class="navbar-toggler-icon"></span> -->
      <img src="./assets/img/user.jpg" class="img-fluid" alt="" />
    </button>
  </div>
  <!-- left box -->
  <ul class="navbar-nav flex-fill mt-1 align-items-center left-nav">
    <li class="nav-item navicon">
      <a class="btn nav-link" (click)="toggleSidebarPin()"
        ><i class="fa fa-bars"></i
      ></a>
    </li>
  </ul>

  <!-- right menu -->
  <div
    class="collapse navbar-collapse right-nav"
    id="collapseBasic"
    [ngbCollapse]="isCollapsed"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <a
          class="btn nav-link"
          (click)="showChangePasswordModal()"
          (keyup.enter)="showChangePasswordModal()"
          tabindex="0"
          ngbTooltip="Cambiar contraseña"
        >
          <i class="fa-solid fa-key"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="btn nav-link" (click)="signOff()">
          <i class="fa-solid fa-arrow-right-from-bracket"></i>
          <span class="link-text">Cerrar sesion</span>
        </a>
      </li>
    </ul>
  </div>
</nav>
