<div class="modal-header bg-white">
  <h5 class="modal-title">Cambiar contraseña</h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  ></button>
</div>
<div class="modal-body bg-white">
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    color="#fff"
    name="upload-spinner"
    [fullScreen]="false"
    type="ball-clip-rotate-multiple"
    size="medium"
  >
    <p style="color: white">Cargando...</p>
  </ngx-spinner>
  <!--  *ngIf="!loading" -->
  <form class="form needs-validation" [formGroup]="form">
    <div class="mb-2">
      <label for="password1" class="form-label"
        >Ingresa tu nueva contraseña</label
      >
      <input
        class="form-control"
        [ngClass]="isValidField('password1') ? 'is-invalid' : ''"
        type="password"
        id="password1"
        formControlName="password1"
      />

      <div
        class="form-text"
        style="color: #e70000"
        *ngIf="isValidField('password1')"
      >
        Ingrese una contraseña valida.
      </div>
      <div class="form-text">
        La contraseña debe tener al menos 6 caracteres
      </div>
    </div>

    <div class="mb-2">
      <label for="password2" class="form-label"
        >Repite tu nueva contraseña</label
      >
      <input
        class="form-control"
        [ngClass]="isValidField('password2') ? 'is-invalid' : ''"
        type="password"
        id="password2"
        formControlName="password2"
      />

      <div
        class="form-text"
        style="color: #e70000"
        *ngIf="
          !form.controls['password2'].errors?.['passwordNoMatch'] &&
          form.controls['password2'].touched &&
          form.controls['password2'].errors
        "
      >
        Ingrese una contraseña valida.
      </div>

      <div
        class="form-text"
        style="color: #e70000"
        *ngIf="form.controls['password2'].errors?.['passwordNoMatch']"
      >
        Las contraseñas no coinciden
      </div>
    </div>
  </form>
</div>
<div class="modal-footer bg-white">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close()"
  >
    <i class="fa-solid fa-chevron-left"></i>
    Volver
  </button>
  <button type="button" class="btn btn-outline-success" (click)="submit()">
    <i class="fa-solid fa-save"></i>
    Guardar
  </button>
</div>
