import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/shared/services/ApiService';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ApiRequest } from 'src/app/shared/util/constant/constant';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.css'],
})
export class ChangePasswordModalComponent implements OnInit {
  constructor(
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private auSrv: AuthService,
  ) {}
  form: FormGroup;
  userId;
  ngOnInit() {
    this.getUserId();
    this.form = this.fb.group({
      password1: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(50),
      ]),
      password2: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(50),
      ]),
    });
  }

  async getUserId() {
    this.userId = await this.auSrv.getUserId();
  }

  isValidField(field: string) {
    this.validatePassword();
    return (
      this.form.controls[field].errors && this.form.controls[field].touched
    );
  }
  validatePassword() {
    //if password1 or password2 are touched
    if (
      this.form.get('password1').touched ||
      this.form.get('password2').touched
    ) {
      if (this.form.get('password2').value != '') {
        if (
          this.form.get('password1').value !== this.form.get('password2').value
        ) {
          this.form.get('password2').setErrors({ passwordNoMatch: true });
          //add errors from the form validation
          this.form.get('password2').setErrors({
            ...this.form.get('password2').errors,
            required: true,
          });
        } else {
          this.form.get('password2').setErrors(null);
        }
      }
    }
  }

  submit() {
    if (this.form.invalid) {
      return Object.values(this.form.controls).forEach((control) => {
        control.markAsTouched();
      });
    }
    this.spinner.show();
    this.apiService
      .putService(ApiRequest.getUsers + '/' + this.userId + '/updatePassword', {
        password: this.form.get('password1').value,
      })
      .subscribe({
        next: () => {
          this.spinner.hide();
          Swal.fire({
            title: 'Exito',
            text: 'Contraseña cambiada',
            icon: 'success',
            confirmButtonText: 'Aceptar',
          }).then((result) => {
            if (result.isConfirmed) {
              this.activeModal.close('Contraseña cambiada');
            }
          });
        },
        error: () => {
          this.spinner.hide();
          Swal.fire({
            title: 'Error',
            text: 'Error al cambiar la contraseña',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
        },
      });
  }
}
